<template>
  <div>
    <b-card>
      <div v-show="!loading.coverages">
        <table-render id="carrier-service-rate-coverages" :schema="schema" :rows="rows" :actions="actions">
        </table-render>
      </div>
      <b-skeleton
        height="40px"
        width="100%"
        class="mt-2 mb-2 spacing-label-field"
        v-show="loading.coverages"
      />
      <div class="table-render-skeleton" v-show="loading.coverages">
        <b-skeleton-table
          :rows="3"
          :columns="schema.length || 3"
          :table-props="{}"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'carrier-service-rate-coverages',
  data() {
    return {
      schema: [],
      rows: [],
      actions: [],
      carrierName: '',
      carrierCode: '',
      loading: {
        coverages: true
      }
    }
  },
  mounted() {
    this.setInitialData()
  },
  computed: {
    ...mapGetters({
      carrierServiceRateCoveragesNY: 'getCarrierServiceRateCoveragesNY',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    carrierServiceRateCoveragesNY() {
      this.rows = this.carrierServiceRateCoveragesNY.rows
    },
    generalLoading: {
      handler () {
        this.loading.coverages = !!this.generalLoading.getCarrierServiceRateCoveragesNY
      }
    }
  },
  methods: {
    setInitialData() {
      this.schema = [
        { label: 'id', sortable: true, key: 'id' },
        { label: 'rate_id', sortable: true, key: 'rate_id' },
        { label: 'carrier_service_id', sortable: true, key: 'carrier_service_id' },
        { label: 'place_id_from', sortable: true, key: 'place_id_from' },
        { label: 'place_id_to', sortable: true, key: 'place_id_to' },
        { label: 'transit_days', sortable: true, key: 'transit_days' },
        { label: 'weight_intervals top limit 1 price', sortable: true, key: 'weight_intervals.1.price' }
      ]
      this.getCarrierServiceRateCoveragesNY()
    },
    getCarrierServiceRateCoveragesNY() {
      const params = { 
        carrier_id: this.$route.params.carrier_id, 
        service_id: this.$route.params.service_id, 
        rate_id: this.$route.params.rate_id  
      }
      this.$store.dispatch('fetchService', { name: 'getCarrierServiceRateCoveragesNY', params })
    }
  }
}
</script>
